<template>
    <div v-if="board" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ board.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>{{ $t("common.작성일") }} : {{ board.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{ $t("common.조회수") }} : {{ board.viewCount }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="board.content"></div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="board-bottom">
            <v-row justify="end" class="row--x-small">
                <v-col v-show="false" cols="auto">
                    <v-row justify="end" class="row--x-small">
                        <v-col cols="auto">
                            <v-btn color="primary" class="w-100px">수정</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">삭제</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">답글</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey-d6" class="w-140px" @click="$router.go(-1)">{{ $t("common.list") }}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import CommentList from "../../comment-list.vue";

export default {
    components: {
        CommentList,
    },
    props: {
        board: { type: Object, default: null },
        me: { type: Object, default: null },
    },
    computed: {
        isBookmark() {
            return this.me ? this.me.bookmarks.find((bookmark) => bookmark._board == this.board._id) : false;
        },
        isLike() {
            return this.me ? this.me.likes.find((like) => like._board == this.board._id) : false;
        },
    },
};
</script>
